<template>
  <div :style="{'background-color': sfondo_3}">
    <!-- Immagine manifesti Desktop-->
    <div class="section-img-manifesti">
      <v-lazy-image
          class="manifesti-background"
          id="img-manifesti-background"
          src="https://funer24-cdn.com/nec_24-min.jpg"
      />
      <!-- <div class="layer" :style="{'background-color': sfondo_2}"></div> -->
    </div>
    <!-- Immagine manifesti Desktop-->
    <!-- Immagine manifesti Mobile-->
    <div class="section-img-manifesti-mobile">
      <v-lazy-image
          class="manifesti-background"
          id="img-manifesti-background"
          src="https://ucarecdn.com/8fb3a316-f75d-4636-97c9-911f09c17372/-/crop/16:9/-/format/auto/"
      />
      <!-- <div class="layer" :style="{'background-color': sfondo_2}"></div> -->
    </div>
    <!-- Immagine manifesti Mobile-->
    <!-- Cerca Defunto -->
    <div class="necrologi-form" :style="{'background-color': sfondo_1 ,'color': testo_sfondo_1 + '!important'}">
      <div v-if="texture" class="texture-manifesti">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 form-search-vega">
        <span class="necrologi-sezione-manifesti" :style="{'color':testo_sfondo_1}">Necrologi</span>
        <div class="search-vega">
          <div class="img-placeholder">
            <IconaProfile class="icon-profile"
                          :style="{'fill': sfondo_1, 'border-right': '1px solid' + sfondo_1}"></IconaProfile>
            <input class="search-manifesti-vega"
                   :style="{'color': testo_sfondo_1}"
                   placeholder="Cerca il tuo caro"
                   v-model="nome_ricerca"
                   @change="filter()">
          </div>
          <button class="btn-search">
            <IconaLente class="icon-search" :style="{'fill': sfondo_1}"></IconaLente>
          </button>
        </div>
      </div>
    </div>
    <!-- /Cerca Defunto -->

    <!-- Lista Necrologi -->
    <div class="necrologi-manifesti container-80">
      <hr :style="{'background-color':testo_sfondo_3}">
      <h3 class="title-last-manifesti-necrologi" :style="{'color':testo_sfondo_3}">Necrologi</h3>
    </div>
    <div class="all-necrologi-sezione-manifesti" id="necrologi-serach" v-on:scroll="onScroll($event)">
      <div
          v-for="item in necrologi"
          :key="item.id"
          class="item-necrologio-manifesti-vega"
      >

        <router-link :to="{name: 'DettaglioNecrologio', params: {id: item.id}}">
          <div class="necrologio_div-manifesti-vega"
               :style="{'background-color':sfondo_3, 'border': '3px solid' + sfondo_1 }">
            <figure class="image is-128x128-vega center_in_div_necrologi-vega">
              <div class="decorazione-necrologio-vega">
                <img class="decorazione-vega" src="~@/assets/TemplateImg/Template_vega/decorazione_necrologio.svg"
                     alt="">
                <v-lazy-image
                    class="is-rounded-vega necrologi_foto-vega-manifesti"
                    :src="getPhotoNecrologioFromCdn(item)"
                    :alt="item.nome"
                />
              </div>
            </figure>
            <br>
            <h1 class="nome_necrologio_center" :style="{'color': testo_sfondo_3 + '!important'}">{{
                item.nome
              }}</h1>
            <span class="eta_necrologio" :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{
                calculateYears(item.data_nascita, item.data_morte)
              }} anni</span>
            <div class="eta_necrologio_div">
              <span class="data_nascita_sinistra" :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{
                  dataNascitaNecrologio(item.data_nascita)
                }}</span>
              <span class="data_morte_destra" :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{
                  dataMorteNecrologio(item.data_morte)
                }}</span>
            </div>
          </div>
        </router-link>

      </div>
    </div>


    <!-- /Lista Necrologi -->
    <!-- Lista Manifesti Desktop-->
    <div class="manifesti container-80">
      <hr :style="{'background-color':testo_sfondo_3}">
      <h3 class="title-last-manifesti-necrologi" :style="{'color':testo_sfondo_3}">Manifesti</h3>
    </div>

    <div class="last-section-manifesti">
      <div class="container-80">

        <b-row class="section-all-manifesti">

          <b-col v-for="item in manifesti"
                 :key="item.id"
                 md="4"
                 cols="10"
                 class="section-item-manifesto">

            <router-link v-if="item.pubblicazione_solo_totem === false"
                         :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

              <v-lazy-image
                  class="photo-sezione-manifesto-vega"
                  :src="getPhotoManifestoFromCdn(item)"
                  alt=""
              />

              <div class="info-funerale-vega" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
                <span>{{ item.nome_manifesto }}</span>
                <span>{{ item.tipologia }}</span>
                <span v-if="item.giorno_ora_messa !== null">{{ giornoOraMessa(item.giorno_ora_messa) }}</span>
              </div>

            </router-link>

          </b-col>

        </b-row>

        <br>
      </div>

    </div>


    <!-- Lista Manifesti Mobile-->
    <div class="section-manifesti-mobile">
      <div class="manifesti-mobile container-80">
        <h3 class="title-manifesti-mobile" :style="{'color':testo_sfondo_3}">Manifesti</h3>
      </div>
      <div class="last-section-manifesti-mobile">
        <div class="container-80">
          <b-row class="section-all-manifesti-mobile">

            <b-col
                v-for="item in manifesti"
                :key="item.id"
                cols="12"
                class="section-item-manifesto-mobile">

              <router-link v-if="item.pubblicazione_solo_totem === false"
                           :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

                <!-- <img class="photo-sezione-manifesto" v-bind:src="item.foto_manifesto"> -->

                <v-lazy-image
                    class="photo-sezione-manifesto-mobile-vega"
                    :src="getPhotoManifestoFromCdn(item)"
                    alt=""
                />

                <div class="info-funerale-mobile-vega" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
                  <span>{{ item.nome_manifesto }}</span>
                  <span>{{ item.tipologia }}</span>
                  <span v-if="item.giorno_ora_messa !== null">{{ giornoOraMessa(item.giorno_ora_messa) }}</span>
                </div>
              </router-link>

            </b-col>
          </b-row>
          <br>
        </div>
      </div>
    </div>
    <!-- Lista Manifesti Mobile -->

    <!-- Sezione manifesti mobile come Homepage senza info defunto -->
    <div class="dots">
      <b-pagination
          class="pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @change="(page) => change(page)"
          first-number
          last-number
          size="lg"
      >
      </b-pagination>
    </div>

  </div>
</template>


<script>
import VLazyImage from "v-lazy-image/v2";
import IconaLente from '@/assets/TemplateImg/search-bg.svg?inline'
import IconaProfile from '@/assets/TemplateImg/profile.svg?inline'
import {mapGetters} from "vuex";
import {
  normalizeDataNascita,
  yearsCalculate,
  photoManifestoFromCDN,
  photoNecrologioFromCDN,
  normalizeDataMorte,
  normalizeGiornoOraMessa

} from "@/utils/util";

export default {
  name: "Manifesti",
  components: {
    IconaLente,
    IconaProfile,
    VLazyImage
  },
  data() {

    return {
      necrologi: [],
      manifesti: null,
      perPage: 9,
      rows: 0,
      nextPageNecrologi: '',
      currentPage: 1,
      manifesti_counter: null,
      nome_ricerca: '',
    }
  },
  computed: {
    ...mapGetters([
      'necrologi_counter',
      'texture',
      'nome_azienda',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_3',
      'testo_sfondo_2',
      'testo_sfondo_1',
    ]),
  },
  metaInfo() {
    return {
      title: 'Manifesti - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  methods: {

    onScroll(event) {
      if (event.target.scrollLeft + event.target.offsetWidth === event.target.scrollWidth) {
        // eslint-disable-next-line no-undef
        axios
            .get(this.nextPageNecrologi)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.count > 0) {
                  if (response.data.next !== null) {
                    this.nextPageNecrologi = response.data.next;
                    this.necrologi.push(...response.data.results)
                  }
                }
              }
            }, (error) => {
              console.log(error);
            });
      }
    },

    filter() {
      this.getDataManifesti()
      this.getDataNecrologi()
    },
    change(page) {
      this.getDataManifesti(page)
    },

    dataNascitaNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    dataMorteNecrologio(data_morte) {
      return normalizeDataMorte(data_morte)
    },

    giornoOraMessa(giorno_ora_messa) {
      return normalizeGiornoOraMessa(giorno_ora_messa)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getPhotoManifestoFromCdn(item) {
      return photoManifestoFromCDN(item)
    },

    getDataNecrologi(page) {

      let url = '';
      if (page !== undefined) {
        url = this.$API_LIST_NECROLOGI + "?page=" + 1;
      }
      if (this.nome_ricerca !== undefined) {
        url = this.$API_LIST_NECROLOGI + "?search=" + this.nome_ricerca
      }

      // eslint-disable-next-line no-undef
      axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.count > 0) {
                this.nextPageNecrologi = response.data.next;
                this.necrologi = response.data.results;
              }
            }
          }, (error) => {
            console.log(error);
          });
    },


    getDataManifesti(page) {

      // eslint-disable-next-line no-undef

      let url = '';

      if (page !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?page=" + page;
      }

      if (this.nome_ricerca !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?search=" + this.nome_ricerca
      }

      if (page !== undefined && this.nome_ricerca !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?page=" + page + "&?search=" + this.nome_ricerca
      }

      // eslint-disable-next-line no-undef
      axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.count > 0) {
                this.rows = response.data.count
                this.manifesti = response.data.results;
              }
            }
          }, (error) => {
            console.log(error);
          });

    },
  },
  created() {

    this.getDataManifesti();
    this.getDataNecrologi()
  }
}
</script>


<style scoped>
@import '~@/assets/css/template_vega/manifesti.css';
</style>
