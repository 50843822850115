<template>
  <div :style="{'background-color': sfondo_3}">
    <!-- Immagine manifesti Desktop-->
    <div class="section-img-manifesti">
      <v-lazy-image
          class="manifesti-background"
          id="img-manifesti-background"
          src="https://funer24-cdn.com/nec_24-min.jpg"
      />
      <!-- <div class="layer" :style="{'background-color': sfondo_2}"></div> -->
    </div>
    <!-- Immagine manifesti Desktop-->
    <!-- Immagine manifesti Mobile-->
    <div class="section-img-manifesti-mobile">
      <v-lazy-image
          class="manifesti-background"
          id="img-manifesti-background"
          src="https://ucarecdn.com/8fb3a316-f75d-4636-97c9-911f09c17372/-/crop/16:9/-/format/auto/"
      />
      <!-- <div class="layer" :style="{'background-color': sfondo_2}"></div> -->
    </div>
    <!-- Immagine manifesti Mobile-->
    <!-- Cerca Defunto -->
    <div class="necrologi-form" :style="{'background-color': sfondo_1 ,'color': testo_sfondo_1 + '!important'}">
      <div v-if="texture" class="texture-manifesti">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 form-search">
        <span class="necrologi-sezione-manifesti" :style="{'color':testo_sfondo_1}">Necrologi</span>
        <div class="search">
          <div class="img-placeholder">
            <IconaProfile class="icon-profile"
                          :style="{'fill': sfondo_1, 'border-right': '1px solid' + sfondo_1}"></IconaProfile>
            <input class="search-manifesti"
                   :style="{'color': testo_sfondo_1}"
                   placeholder="Cerca il tuo caro"
                   v-model="nome_ricerca"
                   @change="filter()">
          </div>
          <button class="btn-search">
            <IconaLente class="icon-search" :style="{'fill': sfondo_1}"></IconaLente>
          </button>
        </div>
      </div>
    </div>
    <!-- /Cerca Defunto -->

    <!-- Lista Necrologi -->
    <div class="necrologi-manifesti container-80">
      <hr :style="{'background-color':testo_sfondo_3}">
      <h3 class="title-last-manifesti-necrologi" :style="{'color':testo_sfondo_3}">Necrologi</h3>
    </div>
    <div class="all-necrologi-sezione-manifesti" id="necrologi-serach" v-on:scroll="onScroll($event)">
      <div
          v-for="item in necrologi"
          :key="item.id"
          class="item-necrologio-manifesti"
      >

        <router-link :to="{name: 'DettaglioNecrologio', params: {id: item.id}}">
          <div class="necrologio_div-manifesti" :style="{'background-color':sfondo_1}">
            <figure class="image is-128x128 center_in_div_necrologi">
              <a href="#">

                <v-lazy-image
                    class="is-rounded necrologi_foto"
                    :src="getPhotoNecrologioFromCdn(item)"
                    alt=""
                />

                <!--                <img-->
                <!--                    class="is-rounded necrologi_foto"-->
                <!--                    v-bind:src="getPhotoNecrologioFromCdn(item)" alt="">-->
              </a>
            </figure>
            <br>
            <h1 class="nome_necrologio_center" :style="{'color':testo_sfondo_1 + '!important'}">{{ item.nome }}</h1>
            <span class="eta_necrologio" :style="{'color':testo_sfondo_1 + '!important', 'opacity':0.4}">{{
                calculateYears(item.data_nascita, item.data_morte)
              }} anni</span>
            <div class="eta_necrologio_div">
              <span class="data_nascita_sinistra" :style="{'color':testo_sfondo_1 + '!important', 'opacity':0.4}">{{
                  dataNascitaNecrologio(item.data_nascita)
                }}</span>
              <span class="data_morte_destra" :style="{'color':testo_sfondo_1 + '!important', 'opacity':0.4}">{{
                  dataMorteNecrologio(item.data_morte)
                }}</span>
            </div>
          </div>
        </router-link>

      </div>
    </div>

    <!-- /Lista Necrologi -->
    <!-- Lista Manifesti Desktop-->
    <div class="manifesti container-80">
      <hr :style="{'background-color':testo_sfondo_3}">
      <h3 class="title-last-manifesti-necrologi" :style="{'color':testo_sfondo_3}">Manifesti</h3>
    </div>

    <div class="last-section-manifesti">
      <div class="container-80">

        <b-row class="section-all-manifesti">

          <b-col v-for="item in manifesti"
                 :key="item.id"
                 md="4"
                 cols="10"
                 class="section-item-manifesto">

            <router-link v-if="item.pubblicazione_solo_totem === false"
                         :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

              <v-lazy-image
                  class="photo-sezione-manifesto"
                  :src="getPhotoManifestoFromCdn(item)"
                  alt=""
              />

              <div class="info-funerale" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
                <span>{{ item.nome_manifesto }}</span>
                <span>{{ item.tipologia }}</span>
                <span v-if="item.giorno_ora_messa !== null">{{ giornoOraMessa(item.giorno_ora_messa) }}</span>
              </div>

            </router-link>

          </b-col>

        </b-row>

        <br>
      </div>

    </div>


    <!-- Lista Manifesti Mobile-->
    <div class="section-manifesti-mobile">
      <div class="manifesti-mobile container-80">
        <h3 class="title-manifesti-mobile" :style="{'color':testo_sfondo_3}">Manifesti</h3>
      </div>
      <div class="last-section-manifesti-mobile">
        <div class="container-80">
          <b-row class="section-all-manifesti-mobile">

            <b-col
                v-for="item in manifesti"
                :key="item.id"
                cols="12"
                class="section-item-manifesto-mobile">

              <router-link v-if="item.pubblicazione_solo_totem === false"
                           :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

                <!-- <img class="photo-sezione-manifesto" v-bind:src="item.foto_manifesto"> -->

                <v-lazy-image
                    class="photo-sezione-manifesto-mobile"
                    :src="getPhotoManifestoFromCdn(item)"
                    alt=""
                />

                <div class="info-funerale-mobile" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
                  <span>{{ item.nome_manifesto }}</span>
                  <span>{{ item.tipologia }}</span>
                  <span v-if="item.giorno_ora_messa !== null">{{ giornoOraMessa(item.giorno_ora_messa) }}</span>
                </div>
              </router-link>

            </b-col>
          </b-row>
          <br>
        </div>
      </div>
    </div>
    <!-- Lista Manifesti Mobile -->

    <!-- Sezione manifesti mobile come Homepage senza info defunto -->
    <!-- <div class="last-manifesti-mobile" :style="{'background-color':sfondo_1}">
      <div class="container-80">
        <h2 class="title-manifesti" :style="{'color':testo_sfondo_1}">Manifesti</h2>
        <b-row>

          <b-col v-for="item in manifesti"
                 :key="item.id"
                 md="4"
                 cols="12"
                 class="item-manifesto-section-manifesti"
          >

            <router-link :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

              <v-lazy-image
                  class="photo-sezione-manifesto"
                  :src="getPhotoManifestoFromCdn(item)"
                  alt=""
              />

            </router-link>

          </b-col>
        </b-row>
        <br>
      </div>
    </div> -->
    <!-- Sezione manifesti mobile come Homepage senza info defunto -->
    <div class="dots">
      <b-pagination
          class="pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @change="(page) => change(page)"
          first-number
          last-number
          size="lg"
      >
      </b-pagination>
    </div>

  </div>
</template>


<script>
import VLazyImage from "v-lazy-image/v2";
import IconaLente from '@/assets/TemplateImg/search-bg.svg?inline'
import IconaProfile from '@/assets/TemplateImg/profile.svg?inline'
import {mapGetters} from "vuex";
import {
  normalizeDataNascita,
  yearsCalculate,
  photoManifestoFromCDN,
  photoNecrologioFromCDN,
  normalizeDataMorte,
  normalizeGiornoOraMessa

} from "@/utils/util";

export default {
  name: "Manifesti",
  components: {
    IconaLente,
    IconaProfile,
    VLazyImage
  },
  data() {

    return {
      necrologi: [],
      manifesti: null,
      perPage: 9,
      rows: 0,
      nextPageNecrologi: '',
      currentPage: 1,
      manifesti_counter: null,
      nome_ricerca: '',
    }
  },
  computed: {
    ...mapGetters([
      'necrologi_counter',
      'texture',
      'nome_azienda',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_3',
      'testo_sfondo_2',
      'testo_sfondo_1',
    ]),
  },
  metaInfo() {
    return {
      title: 'Manifesti - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  methods: {

    onScroll(event) {
      if (event.target.scrollLeft + event.target.offsetWidth === event.target.scrollWidth) {
        // eslint-disable-next-line no-undef
        axios
            .get(this.nextPageNecrologi)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.count > 0) {
                  if (response.data.next !== null) {
                    this.nextPageNecrologi = response.data.next;
                    this.necrologi.push(...response.data.results)
                  }
                }
              }
            }, (error) => {
              console.log(error);
            });
      }
    },

    filter() {
      this.getDataManifesti()
      this.getDataNecrologi()
    },
    change(page) {
      this.getDataManifesti(page)
    },

    dataNascitaNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    dataMorteNecrologio(data_morte) {
      return normalizeDataMorte(data_morte)
    },

    giornoOraMessa(giorno_ora_messa) {
      return normalizeGiornoOraMessa(giorno_ora_messa)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getPhotoManifestoFromCdn(item) {
      return photoManifestoFromCDN(item)
    },

    getDataNecrologi(page) {

      let url = '';
      if (page !== undefined) {
        url = this.$API_LIST_NECROLOGI + "?page=" + 1;
      }
      if (this.nome_ricerca !== undefined) {
        url = this.$API_LIST_NECROLOGI + "?search=" + this.nome_ricerca
      }

      // eslint-disable-next-line no-undef
      axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.count > 0) {
                this.nextPageNecrologi = response.data.next;
                this.necrologi = response.data.results;
              }
            }
          }, (error) => {
            console.log(error);
          });
    },


    getDataManifesti(page) {

      // eslint-disable-next-line no-undef

      let url = '';

      if (page !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?page=" + page;
      }

      if (this.nome_ricerca !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?search=" + this.nome_ricerca
      }

      if (page !== undefined && this.nome_ricerca !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?page=" + page + "&?search=" + this.nome_ricerca
      }

      // eslint-disable-next-line no-undef
      axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.count > 0) {
                this.rows = response.data.count
                this.manifesti = response.data.results;
              }
            }
          }, (error) => {
            console.log(error);
          });

    },
  },
  created() {

    this.getDataManifesti();
    this.getDataNecrologi()
  }
}
</script>


<style scoped>

.page-link {
  color: black !important;
}

.page-item.active, .page-link {
  z-index: 6;
  background-color: black !important;
  border-color: black !important;
  color: #fff !important;
}

a {
  color: white !important;
}

.text-color {
  color: white;
}

/* .container-bg-agency {
  background-image: linear-gradient(to right, #2E3192, #1C1565);
} */

.container-80 {
  width: 80%;
  margin: auto;
  color: black;
}


/* Sezione Sfondo Manifesto */

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}


.section-img-manifesti-mobile {
  display: none;
}

.manifesti-background {
  width: 100%;
  margin-top: 80px;
}

/* Sezione Form Cerca */

.necrologi-form {
  position: relative;
  height: 120px;
  display: flex;
  align-items: center;
}

.form-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.texture-manifesti > img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.send-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-search > span {
  font-size: 25px;
  text-transform: uppercase;
}

.search {
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid black;
  background-color: white;
  z-index: 999999;
}

.img-placeholder {
  display: flex;
}

#fname {
  width: 82%;
  border: none;
  border-left: 1px solid #1F1866;
  padding-left: 10px;
}

input:focus {
  outline: none;
}

.icon-profile {
  width: 62px;
  height: 35px;
  /* margin: 0 15px 0 10px; */
  padding-right: 5px;
  margin-right: 15px;
  /* border-right: 1px solid #1F1866; */
}

.img-placeholder > img {
  width: 20%;
}

.search-manifesti {
  border: none;
  margin-right: 10%;
}

.btn-search {
  border: none;
  background-color: white;
}

.icon-search {
  height: 35px;
  margin-right: 10px;
}

/* Sezione Necrologi */
.necrologi-manifesti {
  display: flex;
  justify-content: space-between;
  color: #1F1866;
  margin-bottom: 20px !important;
  margin-top: 60px !important;
}

.necrologi-sezione-manifesti {
  font-size: 28px !important;
  font-weight: bold;
  /* color: #1A1464; */
}

/* .title-last-manifesti-necrologi {
  color: #1A1464;
} */

.necrologi-manifesti > hr {
  width: 100%;
  height: 2px;
  /* background-color: #1F1866; */
}

.necrologi-manifesti > h3 {
  margin-left: 4%;
}

.all-necrologi-sezione-manifesti {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px !important;
  /* animation-duration: 5s;
  animation-name: slidein-manifesti; */
  overflow: auto;
}

.item-necrologio-manifesti {
  margin-left: 20px;
  margin-right: 20px;
}

/* @keyframes slidein-manifesti {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 2%;
    width: 100%;
  }
} */


.necrologio_div-manifesti {
  width: 165px;
  height: 245px;
  /* margin-left: 30px; */
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
  border-radius: 25px;
  text-align: center;
}

.is-rounded {
  border-radius: 15px;
  border: 2px solid white;
}

.center_in_div_necrologi {
  margin: 0 auto;
  padding-top: 20px !important;
  background-color: transparent;
}

.is-128x128 {
  height: 128px;
  width: 128px;
}

.necrologi_foto {
  height: 128px;
  width: 128px;
}

h1.nome_necrologio_center {
  /* color: white; */
  text-align: center;
  font-weight: 700;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 0 !important;
  margin: auto;
}

span.eta_necrologio {
  /* color: #8284BB; */
  text-align: center;
  font-weight: 600;
  font-size: 12px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.eta_necrologio_div {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
  /* color: #8284BB; */
  font-size: 12px;
}


.item-style {
  max-width: 12.8% !important;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
  border-radius: 8%;
  /* color: white; */
}

.item-necrologi-manifesti {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}

.photo-defunto {
  width: 110px;
  height: 110px;
  margin-top: 20px;
  border-radius: 50%;
}

.date-death {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  /* color: #8284BB; */
  padding-bottom: 10px;
}

/* /Sezione Necrologi */

/* Sezione Manifesti */

.section-manifesti-mobile {
  display: none;
}

.manifesti {
  display: flex;
  justify-content: space-between;
  /* color: #1F1866 !important; */
  margin-top: 40px !important;
}

.manifesti > hr {
  width: 100%;
  height: 2px;
  /* background-color: #1F1866; */
}

.manifesti > h3 {
  margin-left: 4%;
}

.last-section-manifesti {
  margin-bottom: 40px;
}

.section-all-manifesti {
  flex-wrap: wrap;
}

.section-item-manifesto {
  margin-top: 20px;
}

.photo-sezione-manifesto {
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.info-funerale {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-left: 18px;
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
  border-radius: 0 0 20px 20px;
  color: white;
  padding-bottom: 6px;
}

.dots {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.item-dot {
  height: 25px;
  width: 25px;
  line-height: 20px;
  margin-right: 5px;
  margin-left: 5px;
  border-style: solid;
  border-color: #292986;
  border-radius: 50%;
  display: inline-block;
  font-weight: bold;
}


#dot-0 {
  background-color: #272681;
  color: white;
}

.triangle-left,
.triangle-right {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}


.triangle-right {
  border-left: 10px solid #1A1464;;
}

.triangle-left {
  border-right: 10px solid #1A1464;;
}

.last-manifesti-mobile {
  display: none;
}

/* /Sezione Manifesti */


@media (max-width: 767.98px) {

  .section-img-manifesti-mobile {
    position: relative;
    display: block;
  }

  .section-img-manifesti {
    display: none;
  }

  .form-search > span {
    display: none;
  }

  .search {
    width: 100%;
  }

  .search-manifesti {
    margin: 0;
    width: 57%;
  }

  .necrologi-manifesti {
    display: block;
    text-align: center;
    margin-top: 0px !important;
  }

  .necrologio_div-manifesti {
    border-radius: 15px !important;
    /* margin-right: 20px; */
    height: 220px;
    width: 135px !important;
  }

  .is-128x128 {
    height: 115px;
    width: 115px;
    padding-top: 10.5px;
  }

  .necrologi_foto {
    height: 115px;
    width: 115px;
  }

  .eta_necrologio_div {
    font-size: 10px;
    margin-top: 5px;
  }

  .necrologi-manifesti > h3 {
    margin: 0;
    padding: 40px 0 40px 0;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .necrologi-manifesti > hr {
    display: none;
  }

  /* Sezione Manifesti */
  .section-manifesti-mobile {
    display: block;
  }

  .manifesti-mobile {
    text-align: center;
  }

  .title-manifesti-mobile {
    padding-top: 40px;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .last-section-manifesti-mobile {
    margin-bottom: 40px;
  }

  .section-item-manifesto-mobile {
    margin-top: 40px;
  }

  .photo-sezione-manifesto-mobile {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .info-funerale-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    padding-left: 18px;
    /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
    border-radius: 0 0 20px 20px;
    color: white;
    padding-bottom: 6px;
  }

  /* Fine parte mobile manifesti */
  .manifesti {
    display: none;
  }

  .manifesti > h3 {
    margin-left: 0;
    color: white;
  }

  .manifesti > hr {
    display: none;
  }

  .last-section-manifesti {
    display: none;
    background-color: #282884;
  }

  .info-funerale {
    display: none;
  }

  .last-manifesti-mobile {
    display: block;
    /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
  }

  .item-manifesto-section-manifesti {
    margin-bottom: 20px;
  }

  .triangle-left,
  .triangle-right {
    margin: 5px;
  }

  .dots {
    margin-top: 20px;
  }

  .leaflet-bar a, .leaflet-bar a:hover {
    color: #030303 !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .necrologio_div-manifesti {
    width: 155px !important;
  }

  .is-128x128 {
    padding-top: 13.5px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .necrologi-manifesti {
    margin-top: 20px;
  }

  .necrologio_div-manifesti {
    width: 155px !important;
    /* margin-right: 20px; */
  }

  .is-128x128 {
    height: 128px;
    width: 128px;
    padding-top: 13.5px;
  }
}

.page-item.active .page-link {
  background-color: var(--sfondo_1) !important;
  border-color: var(--sfondo_1) !important;
}

</style>
