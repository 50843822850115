<template>
  <div>
    <!-- Immagine manifesti Desktop-->
    <div class="section-img-manifesti" :style="{ 'backgroundColor': sfondo_2 + '!important'}">
      <img
          class="background-img-manifesti"
          id="img-manifesti"
          src="https://ucarecdn.com/2699ea5b-1062-47ee-9814-9f4c67969acf/immaginecopertina1.jpg"
          alt="">
    </div>
    <LogoAgency class="hide-mobile"></LogoAgency>
    <!-- /Immagine manifesti Desktop-->

    <!-- Sezione Ricerca Manifesto -->
    <div class="search-form-mock2"
         :style="{ 'backgroundColor': sfondo_2 + '!important'}">
      <!-- <div v-if="texture !== null" class="texture-manifesti">
        <img :src="texture" alt="">
      </div> -->
      <div class="container-80">
        <div class="search-manifesto-altair">
          <div class="text-necrologi" :style="{ 'background-color': sfondo_1 + '!important' }">
            <div v-if="texture" class="texture-text-necrologi">
              <img :src="texture" alt="">
            </div>
            <span :style="{ 'color': testo_sfondo_1 + '!important' }">Necrologi</span>
          </div>
          <div class="search-altair">
            <div class="img-placeholder">
              <IconaProfile class="icon-profile"
                            :style="{'fill': sfondo_1, 'border-right': '1px solid' + sfondo_1}"></IconaProfile>
              <input class="search-manifesti-altair"
                     :style="{'color': testo_sfondo_2 + '!important', 'background-color': sfondo_2 + '!important'}"
                     placeholder="Cerca il tuo caro"
                     v-model="nome_ricerca"
                     @change="filter()">
            </div>
            <button class="btn-search" :style="{ 'backgroundColor': sfondo_2 + '!important' }">
              <IconaLente class="icon-search" :style="{'fill': sfondo_1}"></IconaLente>
            </button>
          </div>
        </div>
      </div>

      <!-- Lista Necrologi -->
      <div class="container-80 line-services">
        <span :style="{ 'color': testo_sfondo_2 + '!important' }">Necrologi</span>
        <hr :style="{ 'backgroundColor': testo_sfondo_2 + '!important' }">
        <div class="square" :style="{ 'backgroundColor': testo_sfondo_2 + '!important' }"></div>
      </div>
      <div class="all-necrologi-sezione-manifesti" id="necrologi-serach" v-on:scroll="onScroll($event)">
        <div
            v-for="item in necrologi"
            :key="item.id"
            class="item-necrologio-manifesti-altair"
        >

          <router-link :to="{name: 'DettaglioNecrologio', params: {id: item.id}}">
            <div class="necrologio_div-manifesti-altair" :style="{'background-color':sfondo_1}">
              <figure class="image is-128x128-altair center_in_div_necrologi-altair">
                <a href="#">

                  <v-lazy-image
                      class="is-rounded-altair necrologi_foto-altair"
                      :src="getPhotoNecrologioFromCdn(item)"
                      alt=""
                  />
                </a>
              </figure>
              <br>
              <h1 class="nome_necrologio_center" :style="{'color':testo_sfondo_1 + '!important'}">{{ item.nome }}</h1>
              <span class="eta_necrologio" :style="{'color':testo_sfondo_1 + '!important', 'opacity':0.4}">{{
                  calculateYears(item.data_nascita, item.data_morte)
                }} anni</span>
              <div class="eta_necrologio_div">
              <span class="data_nascita_sinistra" :style="{'color':testo_sfondo_1 + '!important', 'opacity':0.4}">{{
                  dataNascitaNecrologio(item.data_nascita)
                }}</span>
                <span class="data_morte_destra" :style="{'color':testo_sfondo_1 + '!important', 'opacity':0.4}">{{
                    dataMorteNecrologio(item.data_morte)
                  }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- /Lista Necrologi -->
    </div>
    <!-- /Sezione Ricerca Manifesto -->

    <!-- Lista Manifesti -->
    <div class="container-manifesti-impaginazione">
      <div v-if="texture" class="texture-manifesti">
        <img :src="texture" alt="">
      </div>
      <div class="search-form-template-altair" :style="{ 'backgroundColor': sfondo_1 + '!important' }">
        <div class="container-80 line-services">
          <span :style="{ 'color': testo_sfondo_1 + '!important' }">Manifesti</span>
          <hr :style="{ 'backgroundColor': testo_sfondo_1 + '!important' }">
          <div class="square" :style="{ 'backgroundColor': testo_sfondo_1 + '!important' }"></div>
        </div>
      </div>

      <div class="last-section-manifesti" :style="{ 'backgroundColor': sfondo_1 + '!important' }">
        <!-- <div v-if="texture" class="texture-manifesti">
          <img :src="texture" alt="">
        </div> -->
        <div class="container-80">

          <b-row class="section-all-manifesti">

            <b-col v-for="item in manifesti"
                   :key="item.id"
                   md="4"
                   cols="10"
                   class="section-item-manifesto">

              <router-link v-if="item.pubblicazione_solo_totem === false"
                           :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

                <v-lazy-image
                    class="photo-sezione-manifesto-altair"
                    :src="getPhotoManifestoFromCdn(item)"
                    alt=""
                />
                <div class="info-funerale-altair" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
                  <div>
                    <span>{{ item.nome_manifesto }}</span>
                    <span v-if="item.giorno_ora_messa !== null">{{ giornoOraMessa(item.giorno_ora_messa) }}</span>
                  </div>
                  <div>
                    <span>{{ item.tipologia }}</span>
                  </div>
                </div>
              </router-link>
            </b-col>
          </b-row>
          <br>
        </div>
      </div>
      <!-- /Lista Manifesti -->

      <!-- Lista Manifesti Mobile-->
      <div class="section-manifesti-mobile" :style="{'background-color': sfondo_1 +'!important'}">
        <div class="last-section-manifesti-mobile">
          <div class="container-80">
            <b-row class="section-all-manifesti-mobile">

              <b-col
                  v-for="item in manifesti"
                  :key="item.id"
                  cols="12"
                  class="section-item-manifesto-mobile">

                <router-link v-if="item.pubblicazione_solo_totem === false"
                             :to="{name: 'DettaglioManifesto', params: {id: item.id}}">
                  <v-lazy-image
                      class="photo-sezione-manifesto-mobile-altair"
                      :src="getPhotoManifestoFromCdn(item)"
                      alt=""
                  />

                  <div class="info-funerale-mobile-altair" :style="{'color':testo_sfondo_1}">
                    <span>{{ item.nome_manifesto }}</span>
                    <span>{{ item.tipologia }}</span>
                    <span v-if="item.giorno_ora_messa !== null">{{ giornoOraMessa(item.giorno_ora_messa) }}</span>
                  </div>
                </router-link>

              </b-col>
            </b-row>
            <br>
          </div>
        </div>
      </div>
      <!-- /Lista Manifesti Mobile-->
      <!-- Impaginazione -->
      <div class="dots" :style="{'background-color': sfondo_1 +'!important'}">
        <b-pagination
            class="pagination-manifesti"
            :style="{'background-color': sfondo_1 +'!important','border-color': sfondo_1 +'!important'}"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @change="(page) => change(page)"
            first-number
            last-number
            size="lg"
        >
        </b-pagination>
      </div>
      <!-- /Impaginazione -->
    </div>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import LogoAgency from './LogoAgency.vue';
import IconaLente from '@/assets/TemplateImg/search-bg.svg?inline';
import IconaProfile from '@/assets/TemplateImg/profile.svg?inline';
import VLazyImage from "v-lazy-image/v2";
import {
  yearsCalculate,
  photoNecrologioFromCDN,
  photoManifestoFromCDN,
  normalizeDataMorte,
  normalizeDataNascita,
  normalizeGiornoOraMessa
} from "@/utils/util";

export default {
  name: "Manifesti",
  components: {
    LogoAgency,
    IconaLente,
    IconaProfile,
    VLazyImage
  },
  data() {

    return {
      necrologi: [],
      manifesti: null,
      perPage: 9,
      rows: 0,
      nextPageNecrologi: '',
      currentPage: 1,
      manifesti_counter: null,
      nome_ricerca: '',
      paddingTop: 80,
    }
  },
  computed: {
    ...mapGetters([
      'necrologi_counter',
      'texture',
      'nome_azienda',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
    ]),
  },
  metaInfo() {
    return {
      title: 'Manifesti - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  methods: {

    onScroll(event) {
      if (event.target.scrollLeft + event.target.offsetWidth === event.target.scrollWidth) {
        // eslint-disable-next-line no-undef
        axios
            .get(this.nextPageNecrologi)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.count > 0) {
                  if (response.data.next !== null) {
                    this.nextPageNecrologi = response.data.next;
                    this.necrologi.push(...response.data.results)
                  }
                }
              }
            }, (error) => {
              console.log(error);
            });
      }
    },

    filter() {
      this.getDataManifesti()
      this.getDataNecrologi()
    },
    change(page) {
      this.getDataManifesti(page)
    },

    dataNascitaNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    dataMorteNecrologio(data_morte) {
      return normalizeDataMorte(data_morte)
    },

    giornoOraMessa(giorno_ora_messa) {
      return normalizeGiornoOraMessa(giorno_ora_messa)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getPhotoManifestoFromCdn(item) {
      return photoManifestoFromCDN(item)
    },

    getDataNecrologi(page) {

      let url = '';
      if (page !== undefined) {
        url = this.$API_LIST_NECROLOGI + "?page=" + 1;
      }
      if (this.nome_ricerca !== undefined) {
        url = this.$API_LIST_NECROLOGI + "?search=" + this.nome_ricerca
      }

      // eslint-disable-next-line no-undef
      axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.count > 0) {
                this.nextPageNecrologi = response.data.next;
                this.necrologi = response.data.results;
              }
            }
          }, (error) => {
            console.log(error);
          });
    },


    getDataManifesti(page) {

      // eslint-disable-next-line no-undef

      let url = '';

      if (page !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?page=" + page;
      }

      if (this.nome_ricerca !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?search=" + this.nome_ricerca
      }

      if (page !== undefined && this.nome_ricerca !== undefined) {
        url = this.$API_LIST_MANIFESTI + "?page=" + page + "&?search=" + this.nome_ricerca
      }

      // eslint-disable-next-line no-undef
      axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.count > 0) {
                this.rows = response.data.count
                this.manifesti = response.data.results;
              }
            }
          }, (error) => {
            console.log(error);
          });

    },
  },
  created() {

    this.getDataManifesti();
    this.getDataNecrologi()
  }
}
</script>


<style scoped>
@import '~@/assets/css/template_altair/manifesti.css';
</style>
